@tailwind base;
@tailwind components;

@import "react-toastify/dist/ReactToastify.css";

.legal {
  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 2em;
    border-bottom: 1px solid #aaa;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.2em;
  }

  h1,
  h2,
  h3,
  h4 {
    padding-top: 20px;
    padding-bottom: 12px;
    font-weight: 500;
  }

  p {
    padding-bottom: 8px;
  }

  ul {
    padding-left: 16px;
    padding-bottom: 20px;
    list-style: disc outside none;
  }

  li {
    padding-left: 2px;
    padding-bottom: 4px;
  }

  a {
    @apply underline font-semibold text-sky-500;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

button:hover:disabled {
  background-color: #444 !important;
}

// Timepicker
.timepicker-ui-wrapper {
  height: auto !important;
}

@tailwind utilities;
